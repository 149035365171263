<template>
  <div class="card-style mx-0 mt-5">
    <!-- <p v-if="!loading" class="font-bold fs-18 secodry-text-3 pb-3">{{ $lang.DOCUMENTS }}</p> -->

    <p v-if="document_list.length==0" class="label-text text-center">{{
        $messages.DOCUMENTS_NOT_FOUND
      }}</p>

    <v-row v-if="document_list.length>0">
      <v-col v-for="(doc,i) in document_list" :key="i" class="text-center" cols="12" md="2">
        <DivLoader v-if="loading"
                   :loading_type="'image'"></DivLoader>
        <div v-else class="border-a cursor-pointer py-3 br-5" @click="isImageDocument(doc.name)? previewImage(doc) :viewDocument(doc.image_url)">

          <v-icon color="primary" size="80">
            mdi-file-document-outline
          </v-icon>
          <p class="secodry-text-2">{{
              doc.document_type.replace(/_/g, ' ').toLowerCase().split(' ')
                  .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                  .join(' ')
            }}</p>
        </div>
        <p v-if="doc.password" class="secodry-text-2">
          p/w: <b>{{ doc.password }}</b>
        </p>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  components: {
    DivLoader: () => import("@/components/ui/DivLoader"),
  },
  data() {
    return {
      selectedDocument: null,
      showImagePreview: false,
      document_type_list:["signature", "selfie", "aadhaar_front", "aadhaar_back", "pan_card",  "electricity_bill", "passport", "driving_license", "voter_id", "passbook"],
      loading: false,
      id: "",
      document_list: [],
    }
  },
  created() {
    this.getDocuments()
  },
  methods: {
    // returning true if image extension is png, jpg or, jpeg 
    isImageDocument(document_name){
      let ext = document_name.split('.')
      ext = ext[ext.length-1]
      return ext=='png'||ext=='jpg'||ext=='jpeg'
    },
    // if isImageDocument returns true then opens image in new tab
    previewImage(doc){
      
      let image_url = doc.image_url;
      let document_type = doc.document_type
        .replace(/_/g, ' ')
        .toLowerCase().split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ')

      let route = this.$router.resolve({ 
        name: "view-image",
        params: {
          image: this.encript(image_url),
          document: this.encript(document_type)
        },
      });
      window.open(route.href, '_blank');
    },
    /* fetching the customer list form server*/
    getDocuments() {
      const self = this;
      self.loading = true;
      let params = {
        customer_id: self.decrypt(this.$route.params.id),
        document_type: "All"
      };
      const successHandler = (response) => {
        if (response.data.success) {
          self.document_list = response.data.document_list
        }
      };
      const finallyHandler = () => {
        self.loading = false;
      };
      self.request_GET(self, self.$urls.GET_DOCUMENT, params, successHandler, null,
          null, finallyHandler);
    },
    /* formatting the documents title */
    documentTitle(title) {
      title = title.replace(/-/g, ' ').toLowerCase()
          .split(' ')
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ');
      return title
    },
    /* open file in new tab */
    viewDocument(url) {
      if (url)
        window.open(url, '_blank');
    }
  }
}
</script>